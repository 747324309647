// serviceWorkerRegistration.js



export function register() {

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
          .then((registration) => {
            // console.log('Service Worker registered with scope:', registration.scope);

            const setWorkerVersion = worker => {
              if (worker) {
                  worker.postMessage({
                      type: 'SET_VERSION',
                      version: process.env.REACT_APP_VERSION
                  });
              }
            };

            // Sprawdź, czy jest już aktywny worker
            if (registration.active) {
                setWorkerVersion(registration.active);
            }
  
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    setWorkerVersion(installingWorker);
                    const event = new Event('serviceWorkerUpdated');
                    window.dispatchEvent(event);
                  }
                }
              };
            };
          })
          .catch((error) => {
            console.log('Service Worker registration failed:', error);
          });
  
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //   window.location.reload();
        // });
      });
    }
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    }
  }
  